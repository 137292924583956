export default {
  $vuetify: {
    close: "Sluiten",
    datePicker: {
      itemsSelected: "{0} geselecteerd",
    },
    noDataText: "Geen gegevens beschikbaar",
    carousel: {
      prev: "Vorig beeld",
      next: "Volgend beeld",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} meer",
    },
    fileInput: {
      counter: "{0} bestanden",
      counterSize: "{0} bestanden ({1} in totaal)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
  },
  cookie_consent: {
    consent_modal: {
      title: "Deze website maakt gebruik van cookies",
      description:
        "Sommige cookies zijn noodzakelijk om de website te laten werken. Andere cookies worden gebruikt " +
        "om anoniem statistieken over het gebruik te verzamelen. Deze cookies worden enkel geplaatst als je ervoor kiest.",
      primary_btn: "Aanvaard alle cookies",
      secondary_btn: "Voorkeuren beheren",
    },
    settings_modal: {
      title: "Cookievoorkeuren beheren",
      save_settings_btn: "Voorkeuren opslaan",
      accept_all_btn: "Aanvaard alle cookies",
      cookie_table_headers: {
        col1: "Cookie",
        col3: "Duur",
        col4: "Omschrijving",
      },
      year: "jaar",
      years: "years",
      day: "dag",
      days: "dagen",
      cookie_preferences: "Cookievoorkeuren",
      language_preferences: "Taalvoorkeur",
      auth_info: "Authenticatiegegevens",
      blocks: {
        header: {
          title: "Cookies",
          description:
            "Deze website maakt gebruikt van cookies. " +
            "Sommige cookies zijn noodzakelijk om de website te laten werken. Andere cookies worden gebruikt " +
            "om anoniem statistieken over het gebruik te verzamelen. Deze cookies worden enkel geplaatst als je ervoor kiest. " +
            "Lees meer hierover in het {0} privacy- en cookiebeleid. </a>",
        },
        necessary_cookies: {
          title: "Noodzakelijke cookies",
          description:
            "Deze cookies zijn noodzakelijk voor de werking van de website. Ze kunnen niet geweigerd worden.",
        },
        analytical_cookies: {
          title: "Analytische cookies",
          description:
            "Met deze cookies wordt het gebruik van de website in kaart gebracht. " +
            "Dit gebeurt volledig anoniem, er wordt niets bijgehouden wat jou kan identificeren.",
        },
      },
    },
  },
  homepage: {
    page_title: "Aangifte PB",
    page_description: "Gratis simulatietool voor Belgische personenbelasting.",
    no_api_key: "Geen API key gevonden. Contacteer support (support@taxmachine.be) om een API key te verkrijgen.",
  },
  footer: {
    language: "Taal",
    privacy_statement: "Privacybeleid",
    cookie_preferences: "Cookievoorkeuren",
  },
  print_dialog: {
    title: "Genereer PDF bestand",
    print: "Download PDF",
    cancel: "Annuleer",
    show_input: "Toon invoer",
    show_administrative_codes: "Toon administratieve codes",
    show_result: "Toon resultaat",
    show_totals: "Toon totalen",
    show_combined_declaration: "Toon gecombineerde aangifte",
    show_calculation_details_text: "Toon berekeningsdetails",
    show_household: "Toon gezinslasten",
    show_real_estate: "Toon onroerende goederen",
    show_loans: "Toon leningen",
    show_comments: "Toon notities",
  },
  new_input: {
    title: "nieuwe berekening aanmaken",
    title_comparison: "nieuwe vergelijking aanmaken",
    read_from_disk: "Lees belastingaangifte of opgeslagen berekening in",
    read_from_disk_kluwer: "Lees Kluwer export (pdf) in",
    empty_input: "Maak nieuwe aangifte aan",
    upload_return: "Selecteer bestand",
    upload_success: "Document succesvol ingelezen",
    only_import_wizards: "Enkel hulpschermen",
    kluwer_import_problem:
      "Mogelijk staat niet alle nodige info in het Kluwer document, gelieve de geïmporteerde hulpschermen in TaxMachine na te kijken.",
    cancel: "Annuleer",
    tax_year: "Aanslagjaar",
    income_year: "Inkomstenjaar",
    city_search_label: "Gemeente",
    create: "Ok",
    marital_status: "Burgerlijke staat",
    allowed_file_formats: {
      tax_return: "Gedownload aanslagbiljet (.pdf)",
      tax_preparation: "Gedownloade voorbereiding van de aangifte (.pdf)",
      pit: "Vanuit deze tool opgeslagen bestand (.pit)",
      tow: "Tax-on-web bestand (.tow)",
    },
    marital_status_list: {
      single_or_divorced: "Ongehuwd, niet wettelijk samenwonend of gescheiden",
      married: "Gehuwd of wettelijk samenwonend",
      widowed: "Weduwnaar of weduwe",
      actually_divorced: "Feitelijk gescheiden",
    },
  },
  save_input: {
    title: "Bewaar aangifte",
    save_regular: "Normaal bestand",
    regular: {
      filename: "Bestandsnaam",
    },
    save_tow: "Exporteer als Tax-On-Web-bestand (.tow)",
    tow: {
      national_id_number: "Rijksregisternummer",
      sender_email: "E-mailadres van de verstuurder",
      filename: "Bestandsnaam",
      national_id_number_not_valid: "Ongeldig nummer",
      sender_email_not_valid: "Ongeldig e-mailadres ",
      attachments: "Bijlagen",
    },
    cancel: "annuleer",
    save: "bewaar",
  },
  tow_files: {
    title: "Bijlagen",
    add_attachment: "Voeg bijlage toe",
    attachment: "bijlage",
    edit_attachment: "Bewerk bijlage",
    upload_pdf: "Upload een PDF bestand",
    upload_file: "Upload een PDF of XLS bestand",
    category: "Categorie (enkel opgeven voor bijlage deel 2)",
    comment: "Notitie",
    cancel: "Annuleer",
    save: "Bewaar",
    required: "Verplicht",
    max_file_size_error: "Bestandsgrootte moet klein dan 2MB zijn",
    comment_size_error: "Notitie mag maximaal 200 karakters bevatten",
    name: "Naam",
    appendix_type: "Type",
    appendix_types: {
      OptionalPart1: "Optioneel deel 1",
      OptionalPart2: "Optioneel deel 2",
      ElectricChargingInvoice: "Factuur elektrisch laadstation",
      ElectricChargingCertificate: "Certificaat elektrisch laadstation",
      Delay: "Uitstel",
      ProfessionalRent: "Professionele huur",
      LegalConstruction: "Juridische constructie",
    },
    categories: {
      cat_0: "Bijlage bij deel 1",
      cat_1:
        "204.3 - Waardeverminderingen voor verwachte verliezen en voorzieningen voor vrijgestelde risico's en lasten",
      cat_2: "276J - Belastingkrediet",
      cat_3: "276K - Gespreide belasting van meerwaarden",
      cat_4: "276T - Vrijstelling voor bijkomend personeel",
      cat_5: "276U - Aftrek voor investeringen",
      cat_6: "276N - Vrijstelling van meerwaarden op bedrijfsvoertuigen",
      cat_7: "328K - Overzicht van activa die op degressieve wijze worden afgeschreven",
      cat_8: "328L - Overzicht van activa waarvoor wordt afgezien van degressieve afschrijving",
      cat_9: "Balans, resultatenrekening en toelichting",
      cat_10: "Kopie van de rekeningen 'Kapitaal' en 'Privé'",
      cat_12: "Details van rubrieken 1 tot 7: vak XVIII: winsten",
      cat_13: "Details van rubrieken 1 tot 16: vak XIX: opbrengsten",
      cat_14: "Details van rubrieken 1 tot 7: vak XXII: winsten en opbrengsten uit eerdere beroepsactiviteiten",
      cat_19: "Lijst van debiteuren en crediteuren",
      cat_22: "276P - Vrijstelling van meerwaarden op binnenvaartschepen bestemd voor commerciële vaart",
      cat_23: "276 W 1 - Vrijstelling voor bijkomend personeel toegewezen aan wetenschappelijk onderzoek",
      cat_24:
        "276 W 2 - Vrijstelling voor bijkomend personeel toegewezen aan de ontwikkeling van het technologisch potentieel van het bedrijf",
      cat_25: "276 W 3 - Vrijstelling voor bijkomend personeel toegewezen als exportmanager",
      cat_26:
        "276 W 4 - Vrijstelling voor bijkomend personeel toegewezen als hoofd van de afdeling Integrale Kwaliteitsbeheer",
      cat_30: "Forfait (pagina's met bedragen)",
      cat_21: "Eigen documenten - overige",
      cat_15: "Eigen documenten - brutowinst / beroepskosten / afschrijvingstabellen",
      cat_17: "Eigen documenten - inkomsten / beroepskosten / afschrijvingstabellen",
    },
  },
  main: {
    start_new_return: "Begin nieuwe aangifte",
    menu_label: "Menu",
    more: "Meer",
    close: "Sluit",
    print: "Genereer PDF",
    upload_return: "Upload aangifte",
    new_return: "Nieuw",
    new_return_for_comparison: "Vergelijk",
    close_return_for_comparison: "Sluit vergelijking",
    save_return: "Exporteer aangifte",
    generate_tow: "Genereer Tax-on-web bestand",
    close_return: "Sluit huidige aangifte",
    verify_return_with_taxcalc: "Verifieer met TaxCalc",
    result_not_ready: "Resultaat niet gereed",
    input: "Invoer",
    payable: "Nog te betalen",
    receivable: "Nog te ontvangen",
    total: "Totale Belasting",
    tax_year_not_valid: "Aangiftes met aanslagjaar {0} worden niet ondersteund",
    server_error: "Server error",
    retry: "Probeer opnieuw",
    verification_result_header: "Verificatie van resultaat",
    help: "Toon hulp",
    help_short: "Hulp",
    swap_codes_dialog: {
      header: "Verkeerde volgorde gedetecteerd!",
      text:
        "Vanaf aanslagjaar 2023 moet de oudste aangever als eerst in de aangifte vermeld worden.\n" +
        "We hebben gedetecteerd dat dit hier niet het geval is. Zullen we dit voor jou in orde brengen?",
      no: "Neen",
      swap: "Ja, wissel de aangevers om",
    },
  },
  savings: {
    title: "Lange termijnsparen",
    save: "Opslaan",
    cancel: "Annuleren",
    long_term_savings_declarant: "Aangever doet aan fiscaal lange termijnsparen",
    long_term_savings_partner: "Partner doet aan fiscaal lange termijnsparen",
    long_term_savings_without_house: {
      payments_contract_after_1989: "Betalingen voor contracten gesloten vanaf 1989",
      payments_contract_before_1989: "Betalingen voor contracten gesloten voor 1989",
      contract_reference: "Contractnummer",
      institution_name: "Naam van het instituut",
      institution_is_company_in_eea: "Institutie is bedrijf in EER",
    },
  },
  code_changes_view: {
    title: "Schakel de wizard in?",
    title_update: "Update de codes?",
    enabling_wizard_description:
      "De wizard zal bij elke aanpassing van de aangifte de codes automatisch optimaliseren.",
    locked_codes:
      "Codes worden ingevuld door de wizard, ze kunnen niet manueel aangepast worden zolang de wizard geactiveerd is.",
    unlocked_codes:
      "Codes zijn relevant voor de wizard maar worden er momenteel niet door ingevuld. De manueel ingevulde waardes blijven bewaard en zijn aanpasbaar.",
    cancel: "Neen",
    cancel_and_disable: "Neen, schakel de wizard uit",
    confirm_and_update: "Ja, update de codes",
  },
  household: {
    title: "Gezinslasten",
    enable: "Inschakelen",
    enable$info: "Als de wizard ingeschakeld is worden de relevante velden automatisch ingevuld.",
    save: "Opslaan",
    cancel: "Annuleren",
    person: {
      dependent: "ten laste",
      not_dependent: "niet ten laste",
      add: "Voeg toe",
      first_name: "Voornaam",
      last_name: "Achternaam",
      birth_date: "Geboortedatum",
      decease_date: "Overlijdensdatum",
      dependent_from: "Ten laste vanaf",
      dependent_to: "Ten laste tot",
      legal_domicile: "Gedomicilieerd bij aangever",
      needs_care: "Zorgbehoevend",
      has_handicap: "Heeft een zware handicap (>= 66%)",
      has_grave_handicap: ">= 80% of >= 15pt",
      child_care_expenses: "Kinderopvangkosten",
      child_care_expenses_days: "Aantal dagen",
      add_period: "Voeg toe",
      cost: "Kost",
      days: "dagen",
      per_day: "/ dag",
      deductible: "aftrekbaar",
      child_care_expenses_enabled: "Geef kinderopvangkosten aan",
      child_care_expenses_not_optimal_and_enabled: "Kinderopvangkosten NIET aangeven is tot {amount} voordeliger.",
      child_care_expenses_not_optimal_and_disabled: "Kinderopvangkosten aangeven is tot {amount} voordeliger.",
      child_care_expenses_not_optimal_and_disabled_fixed: "Kinderopvangkosten aangeven is voordeliger.",
      child_care_expenses_max_days_year_exceeded:
        "Er zijn kosten ingevoerd voor meer dan het aantal dagen in een jaar, wat erop wijst dat er kosten van " +
        "verschillende instellingen zijn opgenomen. TaxMachine beperkt de ingevoerde kosten tot het maximale " +
        "bedrag per dag. Controleer of de berekende aftrekbare kosten juist zijn.",
      per_year: "/ jaar",
      force_code_1038: "Geef kosten niet aan",
      net_means_of_subsistence: "Netto bestaansmiddelen",
      student_income: "Inkomen uit studentenjob",
      child_support: "Onderhoudsgeld",
      pension_income: "Pensioen",
      other_salary: "Andere bezoldigingen of baten",
      other_income: "Andere inkomsten",
      income: {
        gross: "bruto",
        income: "Inkomen",
        costs: "Kosten",
      },
      co_parenting: "Fiscaal co-ouderschap",
      kinship: "Verwantschap",
      kinships: {
        CHILD: "Kind",
        PARENT: "Ouder",
        FOSTER_PARENT: "Pleegouder",
        SIBLING: "Broer/zus",
        GRANDPARENT: "(Over)grootouder",
        GRANDCHILD: "(Achter)kleinkind",
        OTHER: "Andere",
      },
      cancel: "Annuleren",
      save: "Opslaan",
      enable: "Inschakelen",
      enable$info: "Als de wizard ingeschakeld is worden de relevante velden automatisch ingevuld.",
    },
  },
  real_estate_and_loans: {
    loan_reference: "Referentie",
    save: "Opslaan",
    cancel: "Annuleren",
    enable: "Inschakelen",
    enable$info:
      "Als de wizard ingeschakeld is worden de relevante velden automatisch ingevuld en continu geoptimaliseerd.",
    one_loan: "Één lening",
    multiple_loans: "leningen",
    no_codes_to_show: "Geen ingevulde rubrieken.",
    real_estate: {
      title: "Onroerende goederen",
      address_short: "Adres",
      ownership_share: "Eigendomsverdeling",
      income_share: "Inkomstenverdeling",
      declarant: "aangever",
      partner: "partner",
      of_the_ci: "van het KI",
      warning_on_multiple_own_house: "Na te kijken: meerdere woningen bestemd als eigen woning!",
      property: "Onroerend goed",
      property_type: {
        HOUSE: "woning",
        BUILDING: "gebouw (geen woning)",
        LAND: "grond",
        MATERIAL_AND_EQUIPMENT: "materieel en outillering",
      },
      cadastral_income: "Kadastraal inkomen",
      cadastral_income_change: "KI aangepast in {0}",
      income_and_ownership_title: "Inkomsten en eigendom",
      usage_title: "Bestemmingen",
      usage_error: "Bepaal bestemming voor elk moment in {0} dat de woning in bezit is.",
      migrate_purpose_period: "Zet bestemming verder in {0}",
      ownership_share_declarant: "Eigendom aangever",
      ownership_share_partner: "Eigendom partner",
      income_share_declarant: "Inkomsten aangever",
      income_share_partner: "Inkomsten partner",
      purchase_date: "Aankoopdatum",
      sale_date: "Verkoopdatum",
      for_sale_date: "Datum van te koop stelling",
      cadastral_income_update_date: "Datum van KI wijziging",
      cadastral_income_update_amount: "Nieuw KI",
      bare_ownership: "Blote eigendom",
      co_ownership_by_inheritance: "Mede-eigendom door erfenis",
      treaty: "Belastingstelsel",
      treaties: {
        STATE: "Normaal",
        ABROAD_WITH_TAX_AGREEMENT: "Progressievoorbehoud",
        ABROAD_WITHOUT_TAX_AGREEMENT: "Vermindering tot 50%",
      },
      purpose_period: {
        rate: "Deel KI",
        date_from: "Van",
        date_to: "Tot en met",
        purpose: "Bestemming",
        rental_details: {
          gross_rent: "Totale ontvangen huur",
          gross_rent_suffix: "bruto",
          furnitured_rent: "Gemeubelde verhuur",
          furniture_rent_rate: "Roerend deel van de huur",
          furniture_maintenance_cost: "Onderhoudskosten",
          declarant_is_company_director_in_renting_company: "Aangever is bedrijfsleider van hurende onderneming",
          partner_is_company_director_in_renting_company: "Partner is bedrijfsleider van hurende onderneming",
        },
        not_own_house_considered_as_own: "Toch eigen woning",
        not_own_house_reason$info:
          "Als de woning om een bepaalde reden niet zelf bewoond wordt, kan ze soms toch als eigen woning beschouwd worden.",
        not_own_house_reason$info_warning:
          "Als de woning om een bepaalde reden niet zelf bewoond wordt, kan ze soms toch als eigen woning beschouwd worden.<br><br>Opgelet: dit kan enkel aangeduid worden als er voldaan wordt aan de voorwaarden vermeld in de toelichting bij Vak III.",
        purposes: {
          OWN_HOUSE: "Zelf bewoond (eigen woning)",
          OWN_PROFESSION_DECLARANT: "eigen beroep aangever",
          OWN_PROFESSION_PARTNER: "eigen beroep partner",
          NOT_RENTED_OUT: "niet verhuurd",
          RENTED_OUT_PRIVATE: "verhuurd aan particulier",
          RENTED_OUT_PROFESSIONAL: "verhuurd voor professionele doeleinden",
          LEASED_AGRICULTURAL: "verhuurd voor landbouwdoeleinden",
          RENTED_OUT_ON_NON_PROFIT_BASIS: "verhuurd aan non-profit",
          UNPRODUCTIVE: "onproductief",
          EXCLUSION_PERIOD: "uitgesloten periode",
          EXCLUSION_PERIOD$info: "Let op: deze periode wordt niet in rekening gebracht voor de code generatie. Deze is, o.m., voorzien om rekening te kunnen houden met de periode waarbinnen de belastingplichtige niet onderworpen is aan de Belgische belastingen.",
        },
      },
      address: {
        country: "Land",
        city: "Gemeente",
        post_code: "Postcode",
        street_and_number: "Straat en nummer",
      },
      changes_in_income_year: "Aankoop, verkoop of KI-wijziging in {0}",
      loans_title: "Leningen",
      add: "voeg toe",
      add_purpose: "voeg bestemming toe",
      add_loan: "voeg lening toe",
      refinance: "herfinancier",
      cancel: "Annuleer",
      save: "Opslaan",
    },
    refinancing: {
      title: "Herfinanciering",
      of_loan: "Van lening",
      refinancing_purpose: "Doel van de herfinanciering",
      refinancing_purposes: {
        REGULAR: "Gewone herfinanciering",
        RENOVATION: "Herfinanciering met renovatie",
        COMPLETION: "Herfinanciering met voltooiing",
      },
      same_financial_institution: "De herfinanciering werd aangegaan bij dezelfde financiële instelling",
      replaces_older_life_insurance: "Vervangt oude schuldsaldoverzekering",
      outstanding_balance: "Uitstaand saldo",
      extra_for_completion: "Bijgeleend voor voltooiing",
      extra_for_rebuilding: "Bijgeleend voor renovatie",
      extra_for_misc: "Bijgeleend voor andere doeleinden",
    },
    loans: {
      mortgage_loan: "Hypothecaire lening",
      for: "Voor",
      yes: "Ja",
      no: "Nee",
      title: "Lening",
      in_year: "in",
      properties: "Betrokken onroerende goederen",
      loan_date: "Leendatum",
      anticipated_end_date: "Verwachte einddatum",
      loan_amount_total: "Totaal ontleende bedrag",
      loan_amount_mortgage: "Hypothecaire inschrijving",
      total_repayment_done: "Lening werd vervroegd afbetaald",
      total_repayment_date: "Afbetalingsdatum",
      rate_of_loan_professionally_used: "Beroepsgebruik lening",
      declare_as_professional_expenses: "Aftrekbare beroepskosten: {0}",
      capital_and_interest_payments_title: "Kapitaalaflossingen en intresten in {0}",
      capital_repayments: "Kapitaalaflossingen",
      interest_payments: "Intresten (incl. VAA)",
      details_declarant: {
        included_in_loan: "Wie heeft geleend?",
        moment_of_loan_own_house: "Eigen woning op {0}?",
        moment_of_loan_only_house: "Enige woning op {0}?",
        year_of_loan_own_house: "Eigen woning op {0}-12-31?",
        year_of_loan_only_house: "Enige woning op {0}-12-31?",
        year_of_loan_only_house$info:
          "Let op, u mag dit ook aanvinken als u meerdere woningen had die aan bepaalde voorwaarden voldeden. Raadpleeg voor meer details de toelichting voor woonbonus of chèque habitat.",
        year_after_loan_number_of_children: "Aantal kinderen op {0}-01-01?",
        year_after_loan_number_of_children$info: "Gehandicapte kinderen mogen als 2 gerekend worden.",
        always_was_only_house_since_loan_date: "Woning sinds leendatum enige woning?",
        always_was_only_house_since_loan_date$info:
          "De woning is op dit moment de enige woning maar het systeem moet weten of dit altijd het geval is geweest sinds het aangaan van de lening.",
        end_of_income_year_only_house: "Enige woning op {0}-12-31?",
        has_been_own_house_before_2016: "Voor 2016 (gedeeltelijk) eigen woning?",
        own_house_designation_unchanged_since_2016: "Eigen woning status onveranderd sinds 2016?",
        forced_property_ownership_share: "Aandeel in de eigendom",
        forced_property_income_share: "Aandeel in het inkomen",
      },
      property_share_of_all_borrowers: "Eigendomsaandeel alle ontleners",
      property_share_of_all_borrowers$info:
        "Het totale eigendomspercentage van het onroerend goed van alle feitelijke ontleners, inclusief de declarant (indien deze een ontlener is) en de partner (indien deze een ontlener is).",
      institution_is_company_in_eea: "Institutie is onderneming in EER",
      life_insurance_title: "Schuldsaldoverzekering",
      life_insurance_declarant: "Aangever heeft een schuldsaldoverzekering",
      life_insurance_partner: "Partner heeft een schuldsaldoverzekering",
      life_insurance: {
        insured_amount: "Verzekerd bedrag",
        payments: "Betalingen",
        contract_reference: "Contractnummer",
        institution_name: "Naam van het instituut",
        institution_is_company_in_eea: "Institutie is bedrijf in EER",
      },
      loan_purpose: "Doel van de lening",
      payments: {
        date: "Datum",
        amount: "Bedrag",
        specify_payments: "Specifieer betalingen",
        specify_payments$info:
          "Als de bestemming van het onroerend goed niet gedurende het hele jaar hetzelfde is, of als het onroerend goed is aangekocht of verkocht gedurende het jaar, moet elke betaling apart ingegeven worden.",
        add: "Voeg toe",
      },
      on: "op",
      loan_purposes: {
        BUILD_NEW: "Nieuwbouw",
        BUY_NEW: "Verwerving met BTW",
        BUY_OLD: "Verwerving met registratierechten",
        RENOVATION: "Renovatie",
        RENEWING: "Vernieuwingswerken",
        PAY_INHERITANCE_OR_GIFT_TAX: "Betaling van belastingen/rechten voor de verwerving van woning",
        OTHER: "Andere",
      },
      renewing_purpose_tooltip:
        "Controleer of het om vernieuwings- dan wel renovatiewerken gaat; vernieuwingswerken geven mogelijk minder voordelen.",
      house_type: "Type van de woning",
      house_types: {
        MEDIUM_SIZED_HOUSE: "Normale woning",
        LARGE_HOUSE: "Grote woning",
        SOCIAL_HOUSING: "Sociale woning",
      },
      first_date_of_occupation: "Eerste dag van de bewoning",
      first_date_of_occupation$info:
        "Geef de datum in waarop de woning oorspronkelijk in gebruik werd genomen, al dan niet door de aangever of door een vorige bewoner.",
      renovation_amount: "Kostprijs van de renovatie",
      commissioning_date: "Datum van oplevering",
      commissioning_date$info:
        "Datum van ingebruikneming van de nieuwe woning of van de voltooiing van de vernieuwingswerken.",
      months: "maanden",
      covid_loan_extension_months: "Betaling uitgesteld wegens COVID",
      covid_loan_extension_months_number_of: "Aantal",
      warning_title: "Opgelet",
      end_of_income_year_house_was_occupied: "Ingebruikname woning voor {0}-12-31?",
      choose_integrated_housing_bonus: "Opteer voor geïntegreerde woonbonus",
      choose_integrated_housing_bonus_warning:
        "De geïntegreerde woonbonus kan niet gecombineerd worden met een ander gewestelijk belastingvoordeel. Als er gekozen wordt voor het fiscaal regime van de geïntegreerde woonbonus, zal er geen andere lening voor een andere gewestelijk belastingvoordeel in aanmerking meer komen.<br><br>Na het indienen van de aangifte staat de gemaakte keuze vast, zonder mogelijkheid tot herziening en bindend voor de toekomst. Zelfs als in latere aanslagjaren blijkt dat de gemaakte keuze niet de meest gunstige is, kan de belastingplichtige hierop niet via bezwaar terugkomen.<br><br>Bij het maken van een simulatie kan er gewisseld worden tussen de keuzes, na indienen van de aangifte is de ingediende keuze definitief voor latere aanslagjaren.",
      choose_regional_housing_bonus: "Opteer voor regionale woonbonus",
      choose_regional_housing_bonus_warning:
        "Na het indienen van de aangifte staat de gemaakte keuze vast, zonder mogelijkheid tot herziening en bindend voor de toekomst. Zelfs als in latere aanslagjaren blijkt dat de gemaakte keuze niet de meest gunstige is, kan de belastingplichtige hierop niet via bezwaar terugkomen.<br><br>Bij het maken van een simulatie kan er gewisseld worden tussen de keuzes, na indienen van de aangifte is de ingediende keuze definitief voor latere aanslagjaren.",
      choose_federal_housing_bonus: "Opteer voor federale woonbonus",
      choose_federal_housing_bonus_warning:
        "Na het indienen van de aangifte staat de gemaakte keuze vast, zonder mogelijkheid tot herziening en bindend voor de toekomst. Zelfs als in latere aanslagjaren blijkt dat de gemaakte keuze niet de meest gunstige is, kan de belastingplichtige hierop niet via bezwaar terugkomen.<br><br>Bij het maken van een simulatie kan er gewisseld worden tussen de keuzes, na indienen van de aangifte is de ingediende keuze definitief voor latere aanslagjaren.",
      cancel: "Annuleer",
      ok: "Ok",
      confirm: "Bevestig",
      declarant: "Aangever",
      partner: "Partner",
      other_details: "Andere details",
      errors: {
        renewing_purpose_for_loan_before_2005:
          "Het is onmogelijk vernieuwingswerken te selecteren voor leningen aangegaan voor 2005.",
      },
    },
  },
  comparison_editor: {
    title: "Maak aangifte klaar voor vergelijking",
    save: "Vergelijk",
    cancel: "Annuleer",
  },
  input: {
    codeInfo: {
      validation_error_string: "bevat geen geldige tekst",
      validation_error_boolean: "bevat geen geldige boolean",
      validation_error_date: "bevat geen geldige datum",
      validation_error_float: "bevat geen geldig decimaal getal",
      validation_error_foreign_float: "niet alle buitenlands inkomen bevat een geldig decimaal getal",
      validation_error_integer: "bevat geen geldig geheel getal",
      validation_error_foreign_integer: "niet alle buitenlands inkomen bevat een geldig geheel getal",
      validation_error_no_typeinfo: "geen type-info aanwezig",
      validation_error_pattern: "voldoet niet aan patroon",
      validation_error_code: "bevat geen geldige code",
    },
    complexTypes: {
      benefRenteAlimType: {
        country: "Land",
      },
      collectionType: {
        add_item: "Voeg een lijn toe",
      },
      codeType: {
        code: "Code",
        value: "Bedrag",
      },
      contractType: {
        number: "Nr. contract",
        organisation: "Naam van de verzekeringsinstelling",
      },
      electricChargingStationType: {
        numberOfChargingStations: "Aantal laadpalen",
        chargingStation: "Laadpaal",
        type: "Type laadpaal",
        city: "Gemeente",
        postalCode: "Postcode",
        chargePoint: "Laadpunten",
        chargePoints: "laadpunten",
        totalExpenseTit: "Aangever: totaalbedrag van de jaaruitgave",
        totalExpensePart: "Partner: totaalbedrag van de jaaruitgave",
        expenseError: "moet bedrag > 0 zijn",
        chargingStationTypes: {
          UNIDIRECTIONAL: "Unidirectioneel",
          BIDIRECTIONAL: "Bidirectioneel",
          BOTH: "Beide",
        },
        requiredAppendicesMessages:
          "U moet de volgende documenten bij uw aangifte bijvoegen (pdf):" +
          "<br>&nbsp;• De facturen voor de uitgaven" +
          "<br>&nbsp;• Het attest van goedkeuring door een erkende instelling",
      },
      juridType: {
        title: "Juridische constructie",
        nameFounder: "Naam en voornaam van de oprichter of verkrijger van een voordeel",
        name: "Naam van de constructie",
        constructionType: "Rechtsvorm van de constructie",
        address: "Adres van de constructie",
        address1: "Adres van de constructie (1)",
        address2: "Adres van de constructie (2)",
        idNumber: "Eventueel identificatie nr. van de constructie",
        managerName: "Naam van de beheerder van de constructie",
        managerNameAndAddress: "Naam en adres van de beheerder",
        managerAddress1: "Adres van de beheerder (1)",
        managerAddress2: "Adres van de beheerder (2)",
        isArt513Construction: "Is een juridische constructie zoals bedoeld in artikel 5/1, § 3, b, van het WIB 92",
        nameFounderShort: "Voordeel voor",
        nameConstructionShort: "Constructie",
        addressConstructionShort: "Adres constructie",
        nameManagerShort: "Beheerder",
        addressManagerShort: "Adres beheerder",
        isArt513ConstructionShort: "Volgens Art. 5/1, § 3, b, WIB 92",
      },
      looseType: {
        value: "Verlies",
        valuePartner: "Verlies van partner",
        nature: "Aard",
      },
      natureCodeType: {
        code: "Code",
        value: "Bedrag",
        nature: "Aard",
      },
      stringType: {
        partner: "partner",
      },
      textType: {
        notesForSection: "Nota's voor de administratie: vak {0}",
      },
      foreignaccountTypeAndCheckBox: {
        holder: "Naam en voornaam van titularis of beheerder",
        country: "Land",
        reportedToNationalBank: "Gemeld bij de nationale bank",
      },
      foreigninsuranceType: {
        holder: "Naam en voornaam van de verzekeringnemer",
        country: "Land",
      },
      foreignIncomeType: {
        inputIncomeWithButton: "Geef buitenlands inkomen op via de",
        nextToIncome: "knop naast de juiste ingevulde code",
      },
    },
    editor: {
      save: "Bewaar",
      close: "Sluit",
      cancel: "Annuleer",
      take_result: "Overnemen",
      open_calculator: "Open rekenmachine (sneltoets is '+')",
      expand_section: "Toon volledige sectie",
      not_used_for_simulation: "Niet gebruikt voor de simulatie",
      show_taxcalc_help: "Toon toelichting van de aangifte",
      taxcalc_help: "Toelichting van de aangifte",
      foreign_income: "buitenlands inkomen/kosten",
      foreign_income_progression: "progressievoorbehoud",
      foreign_income_deduction: "vermindering tot 50%",
      foreign_income_hide: "Sluit buitenlands inkomen/kosten",
      foreign_income_remove: "Verwijder buitenlands inkomen/kosten",
      foreign_income_add: "Voeg buitenlands inkomen/kosten toe",
      foreign_income_nature: "Aard",
      foreign_income_taxed: "Al belast",
      value: "Waarde",
      country: "Land",
      locked_by_wizards_tooltip: "Klik om de wizard uit te zetten en manueel bewerken mogelijk te maken",
      locked_by_wizards_tooltip_in_field: "Klik op het slotje om manueel te bewerken",
      not_locked_by_wizards_tooltip: "Code niet ingevuld door actieve wizard",
      save_inconsistent_message: "Klik nogmaals op opslaan om inconsistente status te bewaren",
      fatal_errors_present: "Fatale fouten aanwezig - onmogelijk op te slaan",
      errors_present: "Fout in code",
      disable_wizards: {
        title: "Wizard(s) uitschakelen?",
        text: "Na het uitschakelen kunnen de codes handmatig bewerkt worden. Automatische optimalisatie zal echter niet meer werken tot de wizard(s) terug ingeschakeld worden.",
        disclaimer: "* wijziging wordt pas doorgevoerd na bewaren",
        cancel: "Annuleren",
        disable: "Ok",
      },
    },
    view: {
      comparison_header_comparison: "Vergelijking",
      comparison_header_active: "Huidig",
      real_estate_and_loans$info: "Open wizard onroerende goederen en leningen",
      savings$info: "Open wizard lange termijnsparen",
      household$info: "Open wizard gezinslasten",
      part: "Deel",
      comment: "Voeg notitie toe",
      comment_title: "Notitie bij code",
      remove: "Verwijder",
      search_bar_label: "Zoek (druk ' / ')",
      errors_present: "Fouten gedetecteerd",
      no_internet_connection_error: "Geen internetverbinding",
      remote_calc_error: "Error van server.",
      remote_calc_error_message: "Los andere fouten op alvorens deze op te lossen.",
      year: "Jaar",
      tax_year: "aanslagjaar",
      tax_year_tooltip: "Aanslagjaar",
      income_year_tooltip: "Jaar van inkomsten",
      incomplete_tax_year_tooltip:
        "Voorlopige versie: exacte regels nog niet vastgelegd voor dit aanslagjaar, berekening is gebaseerd op voorlopige regels en bedragen",
      city: "woonplaats op 1 januari van het aanslagjaar",
      city_tooltip: "Woonplaats op 1 januari van het aanslagjaar",
      city_search_label: "Selecteer gemeente",
      undo: "Ongedaan maken",
      static_info_not_defined: "StaticInfo niet gedefiniëerd",
      unknown_codes_present_for_region: "Onbekende code(s) aanwezig voor regio",
      unknown_code: "Onbekende code (voor huidig aanslagjaar en huidige regio)",
      no_changes_saved: "Geen wijzigingen bewaard",
      return_updated_success: "Aangifte bijgewerkt - succes",
      return_updated_errors_present: "Aangifte bijgewerkt - nog steeds fouten aanwezig",
      return_updated_new_errors: "Aangifte bijgewerkt - nieuwe fouten opgetreden",
      administrative_codes: "administratieve codes",
      click_for_more_details: "klik hier voor verdere details",
      tax_return: "Aangifte personenbelasting",
      tax_return_comparison: "Vergelijking PB",
      locked_by_wizards_tooltip: "Code ingevuld door actieve wizard",
      not_locked_by_wizards_tooltip: "Code niet ingevuld door actieve wizard",
    },
    validation: {
      or: "of",
      foreignComplete: "buitenlands inkomen vereist een land en een waarde",
      foreignCompleteNature: "buitenlands inkomen vereist een aard",
      foreignCompleteTaxed: 'buitenlands inkomen vereist aangifte "reeds belast"',
      foreignMax: "buitenlands inkomen/kost vereist een waarde <= totale inkomen/kost",
      maxCodeReverse: "{0}: {1} vereist een waarde <= waarde van {2}",
      maxCode: "vereist een waarde <= waarde van {0}",
      maxValue: "vereist een waarde <= {0}",
      minCodeReverse: "{0}: {1} vereist een waarde >= waarde van {2}",
      minCode: "vereist een waarde >= waarde van {0}",
      minValue: "vereist een waarde >= {0}",
      requiresNull: "vereist dat {0} leeg is",
      requiresNullReverse: "{0}: vereist dat {1} leeg is",
      requiresNullOrZero: "vereist dat {0} leeg of 0.0 is",
      requiresNullOrZeroReverse: "{0}: vereist dat {1} leeg of 0.0 is",
      requiresReverse: "{0}: vereist een waarde voor {1}",
      requires: "vereist een waarde in {0}",
      requires_extra: "vereist bijkomende informatie",
      required: "verplicht",
      pattern: "voldoet niet aan patroon: {0}",
      maxLength: "mag maximaal {0} karakters bevatten",
      factory: {
        unknown_function: "onbekende functienaam: {0}",
        not_all_codes_present: "niet alle codes aanwezig in codeInfo: {0}",
        not_all_codes_numbers: "niet alle parameters zijn geldige nummers: {0}",
      },
    },
  },
  warnings: {
    real_costs_lower_than_flat_rate: "De opgegeven werkelijke kosten zijn lager dan het forfait",
    copyright_costs_lower_than_flat_rate_expenses:
      "Auteursrecht: de opgegeven werkelijke kosten zijn lager dan het forfait",
  },
  result: {
    totals_header: "Totalen",
    totals_header_comparison: "Totalen (vergelijking)",
    primary_header: "Aandeel van eerste belastingplichtige",
    secondary_header: "Aandeel van tweede belastingplichtige",
    combined_header: "Beide belastingplichtigen",
    textual_output_header: "Textuele details",
    comments_header: "Notities",
    real_estate_header: "Onroerende goederen",
    loans_header: "Leningen",
    optimization_opportunity_header: "Optimalisatiemogelijkheden",
    retirement_savings_header: "Pensioensparen",
    declarant: "Aangever",
    partner: "Partner",
    max_tax_exemption: "Maximale fiscale vrijstelling",
    formule_30: "Formule 1 (30%)",
    formule_25: "Formule 2 (25%)",
    current_tax_exemption: "Reeds vrijgesteld bedrag",
    additional_deposit: "Extra storting",
    extra_tax_saving: "Supplementaire fiscale besparing",
    percentage_of_savings: "Percentage van de besparing",
    formule_tooltip:
      "Slechts één van deze formules is toepasbaar. De gekozen formule hangt af van het bedrag dat gestort is.",
    extra_tax_saving_tooltip: "Dit geldt als enkel deze investering geoptimaliseerd wordt.",
    long_term_savings_optimization: {
      header: "Langetermijnsparen",
      maximum_exemptible: "Fiscale korf",
      utilized_for_regional_reduction_housing_bonus: "Toegewezen aan gewestelijke woonbonus",
      remaining_balance: "Overgebleven fiscale korf",
      average_marginal_rate: "Bouwsparen - marginaal tarief",
      fixed_rate_savings: "Bouwsparen - vast tarief",
      fixed_rate_long_term_savings: "Langetermijnsparen",
      federal: "Federaal",
      regional: "Gewestelijk",
      already_utilized_for_regional_reduction_housing_bonus: "Ingenomen door gewestelijke woonbonus",
      already_utilized_for_regional_reduction_savings: "Ingenomen door gewestelijk bouwsparen",
      already_utilized_for_regional_reduction_long_term_savings: "Ingenomen door gewestelijk langetermijnsparen",
      utilized_for_reduction_federal_housing_bonus: "Toegewezen aan federale woonbonus",
      extra_premium: "Overgebleven ruimte voor federaal langetermijnsparen",
      supplementary_tax_savings: "Supplementaire fiscale besparing",
    },
    filled_codes: "Ingevulde rubrieken",
    active_return: "actieve berekening",
    income_and_taxes: "Inkomen en belastingen",
    deductions_gotten: "Toegekende verminderingen",
    tax: "Belasting",
    receivable_tax: "Nog te ontvangen belasting",
    payable_tax: "Nog te betalen belasting",
    average_tax_rate: "Gemiddelde aanslagvoet",
    average_tax_rate$info: "volgens de officiële berekening",
    joint_taxable_income: "Gezamenlijk belastbaar inkomen",
    separately_taxable_income: "Afzonderlijk belastbaar inkomen",
    withholding_tax: "Bedrijfs- en roerende voorheffing",
    refundable_amount: "Terugbetaalbaar bedrag",
    tax_additions: "Vermeerderingen",
    real_tax_total: "Totale belasting",
    real_tax_total$info: "totaal verschuldigde belasting",
    tax_payable: "Te betalen belasting",
    prepayments: "Voorafbetalingen",
    globalized: "Geglobalizeerd",
    real_estate_income: "Inkomsten van onroerende goederen",
    investment_income: "Inkomsten van kapitalen en roerende goederen",
    other_income: "Diverse inkomsten",
    professional_income: "Beroepsinkomsten",
    wedding_quotient_used: "Huwlijksquotiënt",
    contributing_spouse_allotment: "Toekenning aan meerwerkende echtgenoot",
    deductions_on_joint_taxable_income: "Vermindering op gezamenlijk belastbaar inkomen",
    tax_on_joint_taxable_income: "belasting op gezamenlijk belastbaar inkomen",
    tax_on_seperately_taxable_income: "belasting op afzonderlijk belastbaar inkomen",
    tax_reductions: "Toegekende belastingverminderingen",
    tax_free_sum_reductions: "Vermindering wegens belastingvrije som",
    reductions_for_replacement_incomes: "Vermindering vervangingsinkomsten",
    reductions_for_replacement_income_unemployment: "Vermindering werkloosheidsuitkering",
    reductions_for_replacement_income_sickness_benefits: "Vermindering ziekte of invaliditeit",
    reductions_for_replacement_income_other: "Vermindering vervangingsinkomsten en pensioen",
    foreign_income_reductions_reduced: "Vermindering buitenlands inkomen: verlaagd",
    foreign_income_reductions_exempt: "Vermindering buitenlands inkomen: vrijgesteld",
    federal_tax_deductions: "Belastingaftrekken: federaal",
    regional_tax_deductions: "Belastingaftrekken: regionaal",
    federal_tax_credit: "Belastingkrediet: federaal",
    regional_tax_credit: "Belastingkrediet: regionaal",
    communal_tax: "Gemeentebelasting",
    special_contribution_for_social_security: "Bijz. bijdrage v. d. sociale zekerheid",
    special_contribution_for_social_security_paid: "Betaalde bijz. bijdrage v. d. sociale zekerheid",
    fed_reg_tax: "Federale en regionale belasting",
    paid_tax: "Betaalde belasting",
    income: "Inkomen",
    total_income: "Totale inkomen",
    total_income$info: "totaal belastbaar, na alle kostaftrekken en verminderingen",
    deductions_on_income: "Vermindering van belastbaar inkomen",
    reductions_on_total_tax: "Belastingverminderingen",
    tax_deductions: "Belastingaftrekken",
    tax_credit: "Belastingkrediet",
    overtime_allowance_57: "Vermindering overwerktoeslag",
    overtime_allowance_66: "Vermindering overwerktoeslag",
    deductions_for_additional_retirement_individual: "Aanvullend pensioen",
    deductions_for_additional_retirement_regular: "Aanvullend pensioen",
    deductions_for_vapw: "VAPW",
    additional_retirement_for_self_employed_persons_premiums: "Aanvullend pensioen",
    passive_house_reduction: "Passiefwoningen  ",
    low_energy_house_reduction: "Lage energiewoningen",
    neutral_energy_house_reduction: "Nulenergiewoningen",
    electrical_motorcycle_reduction: "Elektrische motorfiets of driewieler",
    electrical_car_reduction: "Elektrische vierwieler",
    gifts: "Giften",
    domestic_servant_remuneration: "Bezoldigingen huisbediende",
    childcare_expenses: "Uitgaven kinderopvang",
    retirement_savings_payments: "Pensioensparen",
    retirement_savings_payments_regular: "Pensioensparen",
    retirement_savings_payments_extra: "Pensioensparen",
    employer_stock_payments: "Aandelen werknemer",
    payments_30: "Startende kleine vennootschappen",
    payments_45: "Startende micro-vennootschappen",
    growth_companies_payments: "Groeibedrijven",
    covid19_companies_payments: "Verwerving aandelen Covid",
    losses_on_shares: "Minderwaarden private privaks",
    development_fund_reduction: "Ontwikkelingsfonds",
    adoption_expenses: "Adoptiekosten",
    mortgage_federal_housing_bonus: "Enige woning (interesten en kapitaal)",
    energy_saving_loan: "Uitgaven groene leningen",
    mortgage_before_2005: "Eigen woning voor 2015",
    federal_building_savings: "Bouwsparen",
    federal_long_term_savings: "Lange termijnsparen",
    renovation_of_subsidized_housing_to_let_reduction: "Woning verhuurd via soc. verhuurkant.",
    preservation_of_monuments: "Onderhoud/restauratie eigendom",
    roof_insulation_reduction: "Dakisolatie",
    renovation_reduction: "Verm. renovatieovereenkomsten",
    pwa_and_service_vouchers: "Dienstencheques",
    cheque_habitat_2016: "Wooncheque",
    cheque_habitat: "Wooncheque",
    mortgage_integrated_housing_bonus: "Eigen woning vanaf 2016",
    mortgage_housing_bonus: "Eigen woning 2015",
    mortgage_housing_bonus_before_2015: "Eigen woning voor 2015",
    long_term_savings: "Lange termijnsparen",
    building_savings: "Bouwsparen",
    building_savings_from_2015: "Bouwsparen vanaf 2015",
    building_savings_before_2015: "Bouwsparen voor 2015",
    regular_interests_before_2015: "Gewone interesten voor 2015",
    regular_interests_from_2015: "Gewone interesten vanaf 2015",
    mortgage_before_2005_additional: "Bijkomende interesten",
    mortgage_before_2005_additional_spouse: "Bijkomende interesten",
    work_bonus: "Werkbonus",
    tax_credit_on_tax_free_sum: "Belastingkrediet kinderen",
    tax_credit_for_low_activity_income: "Belastingkrediet lage activiteitsinkomen",
    covid19_companies_payments_not_settled: "Verwerving aandelen Covid 2020",
    charging_station_costs: "Uitgaven vast laadstation",
    waived_rent_1: "Kwijtgescholden huur",
    waived_rent_2: "Kwijtgescholden huur",
    winwin_and_friends_loan: "Winwinleningen / vriendenaandeel",
    winwin_loan: "Winwinleningen",
    coup_de_pouce_loan: "coup_de_pouce_loan",
    state_of_residence_tax: "Verrekenbare woonstaatheffing",
    addition_for_prepayments: "Vermeerdering",
    employer_stock_returned_reduction: "Aandelen werknemer - terugname",
    development_fund_returned_reduction: "Vermindering ontwikkelingsfond - terugname",
    startup_payments_returned_reduction: "Aandelen startende ondernemingen - terugname",
    withholding_tax_other: "Bedrijfsvoorheffing (diverse ink.)",
    tax_credit_business_travel: "Dienstverplaatsingen",
    tax_credit_own_resources_growth: "Belastingkrediet zelfstandigen",
    withholding_tax_on_assets: "Roerende voorheffing",
    withholding_tax_on_assets_other: "Roerende voorheffing (diverse ink.)",
    flat_rate_portion_of_foreign_tax: "Forfaitaire gedeelte buitenlandse belast.",
    bonification: "Bonificatie",
    legal_insurance_premiums: "Premies rechtsbijstandverzekering",
  },
  verification: {
    verification_result_header: "Verificatie van resultaat",
    removed_codes_description:
      "Omdat volgende administratieve codes niet ondersteund worden in TaxCalc, worden ze niet meegenomen in de vergelijking:",
    pending: "Bezig",
    success: "Succesvol",
    failed: "Gefaald",
    calc_difference: "Berekeningsverschil",
    show_taxcalc_calculation: "Toon berekening van TaxCalc",
    show_taxmachine_calculation: "Toon berekening van TaxMachine",
    impossible_at_this_moment: "Momenteel niet mogelijk",
    help: "Toon hulp",
  },
  wizards: {
    savings: "spaarplannen",
    real_estate_and_loans: "onroerende goederen en leningen",
    household: "gezinslasten",
    cancel: "annuleer",
    load_codes: "haal codes op",
    fill_codes: "vul codes in",
    new_wizard: {
      title: "nieuwe wizard",
    },
    real_estate: {
      title: "Onroerend goed",
      cadastral_income: "Kadastraal inkomen",
    },
  },
};
